import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"analytic-card"},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.userResult.fullName)+" ")]),_c(VRow,{staticClass:"analytic-card__content"},[_c(VCardText,[_c('p',[_vm._v("Почта: "+_vm._s(_vm.userResult.email))]),_c('p',[_vm._v("Имя пользователя: "+_vm._s(_vm.userResult.username))]),_c('p',[_vm._v(" Уроков пройдено: "+_vm._s(_vm.userResult.lessonsComplete)+" ")]),_c('p',[_vm._v("Из них симуляций: "+_vm._s(_vm.userResult.vrLessonsComplete))]),_c(VBtn,{attrs:{"to":_vm.link}},[_vm._v(" Данные уроков ")])],1),_c(VCardText,[_c('p',[_c('b',[_vm._v("Средний результат")])]),_c('p',[_vm._v("Оценка: "+_vm._s(_vm.score))]),_c('p',[_vm._v(_vm._s(_vm.detectionAccuracy)+"% выявлено")]),_c('p',[_vm._v("Среднее время выявления "+_vm._s(_vm.timeToSelect)+" сек.")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }