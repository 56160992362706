
import { Component, Vue } from "vue-property-decorator";
import { getAllUsersResults } from "@/api/api";
import TheLoader from "@/components/TheLoader.vue";
import IAllUsersResult from "@/types/all-users-result";
import AnalyticCard from "@/components/AnalyticCard.vue";

@Component({
  components: { AnalyticCard, TheLoader },
})
export default class AnalyticPage extends Vue {
  private analytics: IAllUsersResult[] = [];
  private isLoading = true;

  private get userId(): number {
    return this.$store.getters.userId;
  }

  private async getUserAnalytics(): Promise<void> {
    try {
      this.analytics = await getAllUsersResults();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
  mounted() {
    this.getUserAnalytics();
  }
}
