
import { Component, Prop, Vue } from "vue-property-decorator";
import IAllUsersResult from "@/types/all-users-result";

@Component({
  components: {},
})
export default class AnalyticCard extends Vue {
  @Prop({ required: true }) private readonly userResult!: IAllUsersResult;
  private get link() {
    return `/analytics/${this.userResult.userID}`;
  }

  private get score() {
    const { score } = this.userResult;

    return score ? score.toFixed(2) : 0;
  }

  private get detectionAccuracy() {
    const { detectionAccuracy } = this.userResult;
    return detectionAccuracy ? detectionAccuracy.toFixed(2) : 0;
  }

  private get timeToSelect() {
    const { timeToSelect } = this.userResult;
    return timeToSelect ? timeToSelect.toFixed(2) : 0;
  }
}
